/* eslint-disable no-param-reassign */
/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Tab, Nav } from 'react-bootstrap';
import { graphql, navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import MenuProductCard from '../components/menuProductCard';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { setInsiderPageType, setInsiderListingObject } from '../utils/insiderUtils';
import { brandIdToShortNameConverter } from '../utils/brandIdToNameConverter';
import { analyticsBrandSelected, analyticsViewItemList } from '../utils/analyticsUtils';

export const query = graphql`
  query Brand($crmId: String!) {
    brand: strapiBrand(CRMId: { eq: $crmId }) {
      CRMId
      Name
      NormalizedName
    }

    allApiProduct(filter: { id: { eq: $crmId } }) {
      nodes {
        brandId
        razerCampaign {
          RazerProductIds
          AboutRazer
        }
        products {
          categoryName
          categoryValue
          subcategories {
            name
            value
            products {
              name
              price
              value
              productImage {
                Media {
                  Url
                }
              }
            }
          }
        }
      }
    }

    product: allStrapiProduct(filter: { BrandCrmId: { eq: $crmId } }) {
      nodes {
        CrmId
        BrandCrmId
        Name
        Image {
          alternativeText
        }
      }
    }

    productGroup: allStrapiProductGroup(filter: { Brand: { CRMId: { eq: $crmId } } }) {
      nodes {
        Brand {
          Slug
        }
        GroupList {
          CrmId
          Name
          Slug
          Title
          Description
        }
      }
    }
  }
`;

function currentTab(path, products, slugs, productBrandSlug) {
  let currentBrand;
  const result = {};
  const pathParts = path.split('/');
  if (pathParts?.length >= 1) {
    // eslint-disable-next-line prefer-destructuring
    currentBrand = pathParts[1];
    result.currentBrand = currentBrand;
  }
  if (currentBrand && path) {
    for (let i = 0; i < products?.length; i += 1) {
      const category = products[i];
      const slug = slugs?.find((item) => item?.CrmId === category.categoryValue)?.Slug;
      if (path.substring(1) === productBrandSlug) {
        result.currentCategory = '0';
      }
      category.slug = slug || '';
      if (path?.indexOf(slug) >= 0) {
        result.currentCategory = `${i}`;
      }
    }
  }
  return result;
}

const Brand = ({ data, location, pageContext }) => {
  const [translate] = useTranslation();
  const strapiProducts = data.product.nodes; // Products fetched from cms
  const products = data?.allApiProduct?.nodes[0]?.products; // Products fetched from middleware service

  const razerCampaignIds = data?.allApiProduct?.nodes[0]?.razerCampaign?.RazerProductIds || [];
  const razerCampaignText = data?.allApiProduct?.nodes[0]?.razerCampaign?.AboutRazer;

  let milgoIndex;
  if (location?.state?.tabVal) {
    milgoIndex = products?.findIndex((cat) => cat?.categoryValue === location?.state?.tabVal)?.toString();
  }

  const [searchInputHasValue, setSearchInputHasValue] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [activeKey, setActiveKey] = useState(location.state?.tabVal ? milgoIndex : '0');
  const [subActiveKey, setSubActiveKey] = useState('sub-0');
  const [slug, setSlug] = useState('');

  const productGroups = [];
  const productBrandSlug = data?.productGroup?.nodes?.[0]?.Brand?.Slug;
  const productGroup = productGroups?.find((product) => product?.Slug === slug);
  const title = productGroup?.Title;
  const description = productGroup?.Description;
  const brand = data?.brand?.Name;
  const titleText = `${brand} | ${translate('seo.brand.tiklagelsin')}`;
  const descriptionText = `${translate('seo.brand.description1')} ${brand} ${translate(
    'seo.brand.description2',
  )} ${brand} ${translate('seo.brand.description3')} ${brand} ${translate('seo.brand.description4')}`;
  const schema = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    url: location?.href,
    name: title,
    description,
  };

  const brandId = data?.allApiProduct?.nodes[0]?.brandId;
  const { currentBrand } = currentTab(location.pathname, products, productGroups, productBrandSlug);

  function setSearchResult(childData) {
    setSearchResults(childData);
  }

  useEffect(() => {
    window?.dataLayer?.push({
      cd_pageType: 'listeleme sayfası',
    });
    const shortBrandName = brandIdToShortNameConverter(brandId);
    analyticsBrandSelected(shortBrandName);
    setInsiderPageType('Listing');
    setInsiderPageType('Category');

    const { currentCategory } = currentTab(location.pathname, products, productGroups, productBrandSlug);
    setSlug(products[parseInt(currentCategory, 10)]?.slug);
    if (currentBrand && currentCategory) {
      if (!location.state?.tabVal) setActiveKey(currentCategory);
      if (currentBrand && products[parseInt(currentCategory, 10)].slug) {
        navigate(`/${currentBrand}/${products[parseInt(currentCategory, 10)].slug}`, { replace: true });
      }
    }
    setInsiderData(0);
  }, []);

  const setInsiderData = (index = 0) => {
    const brandName = data?.brand?.Name || '';
    const itemsArray = products?.[index]?.subcategories?.[0]?.products?.map((item) => ({
      id: item?.value,
      name: item?.name,
      taxonomy: brandName ? [brandName] : [],
      currency: 'TRY',
      unit_price: item?.price,
      unit_sale_price: item?.price,
      url: `${location?.origin}/a/cproduct?brandId=${brandId}&productId=${item?.value}`,
      product_image_url: item?.productImage?.Media?.Url,
      custom: {
        brand_name: brandName,
      },
    }));
    setInsiderListingObject(itemsArray);

    let itemList = {};
    const listName = [];
    const fetchedData = products?.[index];
    const subIndex = parseInt(subActiveKey.replace('sub-', ''), 10);
    listName.push(fetchedData?.categoryName);
    if (fetchedData?.subcategories?.length > 1) {
      itemList = fetchedData?.subcategories?.[subIndex]?.products;
      const subCatName = fetchedData?.subcategories?.[subIndex]?.name;
      if (subCatName) listName.push(subCatName);
    } else {
      itemList = fetchedData?.subcategories?.map((subCategory) => subCategory?.products);
    }

    window?.localStorage?.setItem('item_list_name', listName.join(' | '));
    analyticsViewItemList({
      listId: '00000000-0000-0000-0000-000000000000',
      listName: listName.join(' | '),
      brandName,
      items: itemList,
    });
  };

  useEffect(() => {
    setInsiderData(parseInt(activeKey, 10));
  }, [activeKey, subActiveKey]);

  useEffect(() => {
    setSearchInputHasValue(!!(document.getElementById('search-input')?.value.trim().length > 2));
  }, [searchResults]);

  return (
    <Layout
      title={translate('seo.brand.tiklagelsin')}
      sidebar={false}
      brandName={data?.brand?.Name}
      searchEnabled
      searchData={products}
      handleCallBack
      setSearchResult={(childData) => setSearchResult(childData)}
      location={location}
    >
      <SEO
        title={titleText}
        description={descriptionText}
        siteUrl={location?.pathname}
        schemaMarkup={schema}
        deepLinkUrl={`branddetail/${brandId}`}
      />

      {searchInputHasValue ? (
        <div style={{ backgroundColor: '#f2f2f2', padding: '0 1rem' }}>
          <Row className="menu-product-card-wrapper">
            {searchResults?.map((product, index) => (
              <Col xs={12} sm={6} md={6} lg={6} style={{ padding: 0 }} key={`${index + 1}-${product?.value}`}>
                <LazyLoadComponent threshold={50}>
                  <MenuProductCard
                    brandsContext={pageContext.brandsContext}
                    data={product}
                    brandId={brandId}
                    razerCampaignText={
                      Object.values(razerCampaignIds).indexOf(product?.value) > -1 ? razerCampaignText : null
                    }
                  />
                </LazyLoadComponent>
              </Col>
            ))}
            {searchResults?.length === 0 && <span style={{ fontWeight: 'bold' }}>Aradığınız ürün bulunamadı.</span>}
          </Row>
        </div>
      ) : (
        <div className="products-tabs-container">
          <Tab.Container
            id="menu-tabs"
            defaultActiveKey="0"
            activeKey={activeKey}
            onSelect={(k) => {
              setSlug(products[parseInt(k, 10)].slug);
              setActiveKey(`${k}`);
              setSubActiveKey('sub-0');
              if (currentBrand && products[parseInt(k, 10)].slug) {
                navigate(`/${currentBrand}/${products[parseInt(k, 10)].slug}`, {
                  replace: true,
                });
              }
            }}
          >
            <nav className="products-tabs main-tabs nav nav-tabs">
              {products?.map((categories, index) => (
                <>
                  <Nav.Link
                    eventKey={index}
                    className="nav-item nav-link"
                    active={index === parseInt(activeKey, 10)}
                    href={categories?.slug ? `/${currentBrand}/${products[index]?.slug}` : `/${currentBrand}`}
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    {index === parseInt(activeKey, 10) ? (
                      <h1>{`${categories?.categoryName}`}</h1>
                    ) : (
                      <span>{`${categories?.categoryName}`}</span>
                    )}
                  </Nav.Link>
                </>
              ))}
            </nav>

            <Tab.Content>
              {products?.map((categories, index) => (
                <Tab.Pane
                  className="products-tab"
                  eventKey={index}
                  title={categories?.categoryName}
                  key={`products-tab-${index + 1}`}
                >
                  {categories?.subcategories?.length > 1 ? (
                    <Tab.Container
                      defaultActiveKey="sub-0"
                      activeKey={subActiveKey}
                      onSelect={(k) => setSubActiveKey(k)}
                    >
                      <nav className="products-tabs sub-tabs nav nav-tabs">
                        {categories?.subcategories?.map((subcategoryItem, subIndex) => {
                          subcategoryItem.slug = products[index].slug;
                          return (
                            <Nav.Link
                              eventKey={`sub-${subIndex}`}
                              className="nav-item nav-link"
                              active={`sub-${subIndex}` === subActiveKey}
                            >
                              {products[parseInt(activeKey, 10)]?.subcategories?.some(
                                (category) => category?.slug === subcategoryItem?.slug,
                              ) ? (
                                <h2>{subcategoryItem.name}</h2>
                              ) : (
                                <span>{subcategoryItem.name}</span>
                              )}
                            </Nav.Link>
                          );
                        })}
                      </nav>
                      <Tab.Content className="sub-tab-content">
                        {categories?.subcategories?.map((subcategoryItem, subIndex) => {
                          subcategoryItem.slug = products[index].slug;
                          return (
                            <Tab.Pane eventKey={`sub-${subIndex}`}>
                              <Row style={{ padding: '1rem 0' }} className="products-tab">
                                {subcategoryItem?.products?.map((product) => {
                                  product.slug = products[index].slug;
                                  return (
                                    <Col
                                      xs={12}
                                      sm={6}
                                      md={6}
                                      lg={6}
                                      className="no-padding"
                                      key={`${product?.name}-${product?.value}`}
                                    >
                                      <LazyLoadComponent threshold={50}>
                                        <MenuProductCard
                                          brandsContext={pageContext.brandsContext}
                                          data={product}
                                          allStrapiProducts={strapiProducts}
                                          brandId={brandId}
                                          active={products[parseInt(activeKey, 10)]?.subcategories?.some(
                                            (category) => category?.slug === product?.slug,
                                          )}
                                          razerCampaignText={
                                            Object.values(razerCampaignIds).indexOf(product?.value) > -1
                                              ? razerCampaignText
                                              : null
                                          }
                                        />
                                      </LazyLoadComponent>
                                    </Col>
                                  );
                                })}
                              </Row>
                            </Tab.Pane>
                          );
                        })}
                      </Tab.Content>
                    </Tab.Container>
                  ) : (
                    <Tab.Container>
                      <Tab.Content className="sub-tab-content">
                        <Row>
                          {categories?.subcategories?.[0]?.products?.map((product) => {
                            product.slug = categories?.slug;
                            return (
                              <Col
                                xs={12}
                                sm={6}
                                md={6}
                                lg={6}
                                className="no-padding"
                                key={`${product?.name}-${product?.value}`}
                              >
                                <LazyLoadComponent threshold={50}>
                                  <MenuProductCard
                                    brandsContext={pageContext.brandsContext}
                                    data={product}
                                    allStrapiProducts={strapiProducts}
                                    brandId={brandId}
                                    active={products[parseInt(activeKey, 10)]?.slug === product?.slug}
                                    razerCampaignText={
                                      Object.values(razerCampaignIds).indexOf(product?.value) > -1
                                        ? razerCampaignText
                                        : null
                                    }
                                  />
                                </LazyLoadComponent>
                              </Col>
                            );
                          })}
                        </Row>
                      </Tab.Content>
                    </Tab.Container>
                  )}
                </Tab.Pane>
              ))}
            </Tab.Content>
          </Tab.Container>
        </div>
      )}
    </Layout>
  );
};

Brand.propTypes = {
  location: PropTypes.object,
  pageContext: PropTypes.object,
  data: PropTypes.object,
};

export default Brand;
